import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import * as pagesConfig from '../../../config/pages-config';
import { RoutesUtilsService } from '@vfde-fe/ngx-lib/services/routes-utils';
import { CustomErrorHandlerService } from '@vfde-fe/ngx-lib/services/custom-error-handler';
import { SpinnerService, SpinnerLoader } from '@vfde-fe/ngx-lib/components/spinner';
import { AUTHENTICATION_ERROR_CODE, AUTHENTICATION_WHITELIST, ONELOGIN_URL, MTAN_STATIC_VALUES } from './../../common/constants/Defines';
import { CoreAuthenticationService } from '@vfde-fe/ngx-lib/services/core-authentication';
import { Helper } from '@vfde-fe/ngx-lib/services/helper-utils';
import { API_URLS } from 'src/app/common/constants/routes-config';
import { HEADER_CONTEXT_WHITE_LIST } from '@vfde-fe/ngx-lib/pipes/enterprise-translate';
import { map, catchError } from 'rxjs/operators';
import { ValidationService } from './validation.service';
import { ProcessService } from './process.service';

/**
 * HTTPClientInterceptor
 */
 @Injectable()
 export class HTTPClientInterceptor implements HttpInterceptor {

    private pagesConfig: any;

    /**
     * constructor
     * @param router {Router}
     * @param processService {ProcessService}
     * @param validationService {ValidationService}
     * @param customErrorHandlerService {CustomErrorHandlerService}
     * @param spinnerService {SpinnerService}
     * @param authenticationService {CoreAuthenticationService}
     * @param routesUtilsService {RoutesUtilsService}
     * @param helper {Helper}
     */
  public constructor (
      private router: Router,
      private processService: ProcessService,
      private validationService: ValidationService,
      private customErrorHandlerService: CustomErrorHandlerService,
      private spinnerService: SpinnerService,
      private authenticationService: CoreAuthenticationService,
      private routesUtilsService: RoutesUtilsService,
      private helper: Helper) {
      this.pagesConfig = pagesConfig.config;
    }

    /**
     * intercept
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    public intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const data = request.url.startsWith('{') ? JSON.parse(request.url) : null;
      request = request.clone({url: data ? data.url : request.url});

      if (request.url.indexOf(environment.baseUrlNil) !== -1) {

        request = request.clone({ setHeaders: {
            'x-vf-api': (new Date().valueOf()).toString(),
            'x-vf-clientID': 'MyVFWeb',
            },
        });
       }
      if (HEADER_CONTEXT_WHITE_LIST.CONTEXT.indexOf(this.helper.getContext()) > -1
        && this.helper.detectIsUrlInWhiteList(request.url, HEADER_CONTEXT_WHITE_LIST.URLS)) {

            request = request.clone({ setHeaders: {
                'X-USE-CONTEXT': this.helper.getContext(),
                },
            });
        }

      const disableSpinner = request.headers.get('disableSpinner') === 'true';
      const loader: SpinnerLoader = !disableSpinner ? this.spinnerService.startLoader() : null;

        return next.handle(request).pipe( map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (loader) {
                    this.spinnerService.endLoader(loader, true);
                  }
                  if (this.processService.checkIfProcessUri(request.url)) {
                    // Successful Response;
                    return this.processSuccessResponse(event);
                  }
            }

            return event;
        }),
        catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse) {
                if (loader) {
                    this.spinnerService.endLoader(loader, false);
                }
            }

            return this.processErrorResponse(error);
        }));
    }

    /**
     * processSuccessResponse
     * @param {any} response
     */
    public processSuccessResponse (response: any) {
      const result = response;
      if (result.body.stepName) {
        const route = this.processService.handleGenericRouting(result.body.stepName);
        if (route) {
          this.validationService.addStepAttributes(result.body);
          if (this.processService.navigate) {
            this.router.navigate([route]);

          }

          return response;
        } else {
          console.warn('Step name retrieved does not match to any route');

          return response;
        }
      } else if (result.body.lastStep || (response.url && response.url.indexOf(API_URLS.LOGIN.SESSION_START)> -1) ) {
        return response;
      } else {
        return throwError('Next step not retrieved from BE');

      }
    }

    /**
     * processErrorResponse to handle generic error on level of application (404,500,401,403,etc..)
     * @param {HttpErrorResponse} response
     */
    // eslint-disable-next-line complexity
    public processErrorResponse (response: HttpErrorResponse) {
        if (response instanceof HttpErrorResponse) {
      let errorCode = null;
      response = this.formatOperationError(response);
        try {
            if (response.url.indexOf(environment.baseUrlVluxgate) !== -1) {
                errorCode = response.error.operationError ? response.error.operationError.code : null;
            } else if (response.url.indexOf(environment.baseUrlDxl) !== -1 || response.url.indexOf(environment.baseUrlDxlPreProd) !== -1 || response.url.indexOf(environment.baseUrlDxlProd) !== -1 || response.url.indexOf(environment.baseUrlDxlProdV2) !== -1) {
              errorCode = response.error.code;
           } else if (response.url.indexOf(environment.baseUrlNil) !== -1) {
                errorCode = response.error.faultMessage ? response.error.faultMessage.code : null;
            } else {
                errorCode = response && response.error && response.error.operationError ? response.error.operationError.code : null;
            }
        } catch (e) {
        // console.log(e);
        // do nothing and not to stop code sequence in case response in not in JSON format
        return throwError(response);
      }
      if (response.url.indexOf(environment.cmsConfigPath) > -1 || response.url.indexOf(environment.cmsErrorMessagesPath) > -1 || response.url.indexOf(environment.cmsCommonWording) > -1 || response.url.indexOf(environment.configPath) > -1 || response.url.indexOf(environment.wordingPath) > -1) {
        return throwError(response);
      }
      switch (response.status) {
        case 401:
          // redirect to login page
          let foundWhiteList = false;
          if (errorCode === AUTHENTICATION_ERROR_CODE.MINT_AUTHENTICATION_REQUIRED) {
            AUTHENTICATION_WHITELIST.forEach((item) => {
              if (this.router.url.indexOf(this.pagesConfig[item].route) > -1) {
                foundWhiteList = true;
              }
            });
            if (foundWhiteList) {
              return throwError(response);
            } else {
              this.authenticationService.logout();
              this.router.navigate([this.pagesConfig.login.route]);

              return throwError(response);
            }
          } else if (errorCode === AUTHENTICATION_ERROR_CODE.NIL_AUTHENTICATION_REQUIRED) {

            window.location.href = API_URLS.LOGOUT.LOGOUT + '?targetURL=' + ONELOGIN_URL + pagesConfig.config.login.route + '?targetURL=' + encodeURIComponent(encodeURIComponent(window.location.href));

            return throwError(response);
          }

          return throwError(response);

        default:
              if (response.status === 403 && (errorCode === MTAN_STATIC_VALUES.RESEND_MAXED_OUT || errorCode === MTAN_STATIC_VALUES.TAN_EXPIRED)) {
                  return  throwError(response);
          } else if (response.status === 400 && errorCode === MTAN_STATIC_VALUES.CUSTOMER_ONBOARDING) {
              return throwError(response);

          }
          const currentModule = this.processService.getCurrentModule() !== '' ? this.processService.getCurrentModule() : undefined;
          const currentUsecase = this.routesUtilsService.getCurrentUsecase() !== '' ? this.routesUtilsService.getCurrentUsecase() : undefined;
          if (!errorCode) {
            this.customErrorHandlerService.getErrorDescription(currentModule, undefined, response.status.toString(), true, true, false, currentUsecase);

            return throwError(response);
          } else {
            this.customErrorHandlerService.getErrorDescription(currentModule, errorCode, response.status.toString(), true, true, false, currentUsecase);

            return throwError(response);
          }
      }
     }
    }
     /** format operationError */
  public formatOperationError (error) {
    if (error.error) {
      Array.isArray(error.error.operationError) ? error.error.operationError = error.error.operationError[0] : '';
    }

    return error;

}
  }
